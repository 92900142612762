import React from 'react';
import { ReactReader } from "react-reader";
import autoBind from 'react-autobind';
import _ from 'underscore';


class EpubReader extends React.Component {

  constructor(props) {
    super(props);
    const Orpheus = window.__orpheus__ || {};

    this.state = {
      readingLocation: null, // "epubcfi(/6/2[cover]!/6)",
      title: Orpheus.ePubTitle,
      ePubUrl: Orpheus.ePubUrl,
    };

    autoBind(this);
  }

  componentDidUpdate = () => {
    const cssLink = document.createElement("link");
    cssLink.href = "/wp-content/themes/orpheus/static/css/reading.css";
    cssLink.rel = "stylesheet";
    cssLink.type = "text/css";
    const frames = document.getElementsByTagName("iframe");
    let images = [];
    for (let i = 0; i < frames.length; i++) {
      frames[i].contentDocument.head.appendChild(cssLink);
    }
  }

  componentDidMount = () => {
    document.addEventListener('keydown', this.handleKeyPress, false);
  }

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.handleKeyPress, false);
  }

  onLocationChanged = (epubcifi) => {
    this.setState({
      readingLocation: epubcifi,
    });
  }

  handleKeyPress = (e) => {
    // handle keypress events
  }

  render() {
    const { ePubUrl, title } = this.state;

    return (
      <div
        className="epubReader"
        style={{
          height: "100vh",
          width: "100vw",
          padding: '0 0 0 0',
          background: '#fff'
        }}
      >

        {ePubUrl ?
          <ReactReader
            url={ePubUrl}
            title={title}
            location={this.state.readingLocation}
            locationChanged={this.onLocationChanged.bind(this)}
            style={{
              p: {
                background: "blue !important",
              },
            }}
          />
        : ''}
      </div>
    );
  }
}

export default EpubReader;
